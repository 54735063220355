import { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ResultsContext } from '../../context/ResultsContext'
import { useModal } from '../../context/Modal'
import { useSnackbar } from '../../context/Snackbar'
import { createResourceGroup, updateGroup, updateResources } from '../../store/resourcegroups'
import ResourceGroupFilters from '../ResourceGroups/ViewAllGroups/ResourceGroupFilters'
import ResourceGroupCard from '../ResourceGroups/ViewAllGroups/ResourceGroupCard'
import NewGroupForm from './NewGroupForm'
import DeleteGroupConfirm from './DeleteGroupConfirm'
import trashIcon from '../../assets/images/trash.png'

export default function NewGroupModal({ group, setSelectResources, isUpdateGroup }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const resourceGroups = useSelector(state => state.resourceGroups.all)
    const { closeModal, setClassName, setModalContent } = useModal()
    const { openSnackbar } = useSnackbar()
    const { resourceSelection, setResourceSelection } = useContext(ResultsContext)
    const [name, setName] = useState(group?.name || "")
    const [description, setDescription] = useState(group?.description || "")
    const [isPrivate, setIsPrivate] = useState(group ? group.isPrivate : true)
    const [isNewGroup, setIsNewGroup] = useState(true)
    const [existingGroup, setExistingGroup] = useState({})
    setClassName("relative rounded-lg z-60 w-full max-w-xl p-8 flex items-center justify-center overflow-y-auto")

    const createGroup = () => {
        if (resourceSelection.length && name.length) {
            dispatch(createResourceGroup({ resources: resourceSelection, group: { name, description, isPrivate } }))
                .then(async data => {
                    if (data?.resourceGroupId) {
                        const { resourceGroupId } = data
                        openSnackbar('success', 'Resource Group created.')
                        navigate(`/resourceGroup/${resourceGroupId}`)
                    }
                })
                .catch(() => {
                    openSnackbar('error', 'An error occurred ')
                })
            closeModal()
        }
    }

    const updateResourceGroup = () => {
        dispatch(updateGroup(group.id, { name, description, isPrivate }))
            .then(async () => {
                openSnackbar('success', 'Resource Group updated.')
            })
        closeModal()
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (group?.id) {
            updateResourceGroup()
        }
        else if (existingGroup?.id) {
            addToExistingGroup()
        }
        else createGroup()
        setResourceSelection([])
    }

    const addToExistingGroup = () => {
        if (!resourceSelection.length) return
        updateResources(existingGroup.id, resourceSelection, 'add')
            .then(async res => {
                if (res.success) {
                    navigate(`/resourceGroup/${existingGroup.id}`)
                    setSelectResources(false)
                }
            })
        closeModal()
    }

    const isNewGroupOrselectedGroup = () => existingGroup?.id || name.length

    return (
        <div className={`w-full p-8 space-y-8 bg-zinc-800 rounded-lg text-white`}>
            <div className='h-1/4 flex items-center justify-center pb-4'>
                <h1 className='text-2xl'>Just a few more details</h1>
            </div>
            <form onSubmit={handleSubmit} className='flex flex-col h-2/3 w-full space-y-2 rounded'>

                {!group ?
                    <select value={isNewGroup} onChange={(e) => setIsNewGroup(e.target.value === "true" ? true : false)} className='rounded bg-zinc-700 border-2 border-zinc-500 px-3 py-2 w-fit text-xl cursor-pointer' placeholder='New group'>
                        <option value={true}>New</option>
                        <option value={false}>Existing</option>
                    </select> : <></>}

                {isNewGroup ?
                    <NewGroupForm name={name} setName={setName} description={description} setDescription={setDescription} isPrivate={isPrivate} setIsPrivate={setIsPrivate} />
                    :
                    <div>
                        <ResourceGroupFilters />
                        <div className='h-96 overflow-y-scroll space-y-1'>

                            {resourceGroups && Object.values(resourceGroups).length ?
                                Object.values(resourceGroups).reverse().map(group => {
                                    return (existingGroup?.id === group.id ?
                                        <div className='border-2 rounded'>

                                            <ResourceGroupCard group={group} key={group.id} isAddingResources={true} setExistingGroup={setExistingGroup} />
                                        </div> : <ResourceGroupCard group={group} key={group.id} isAddingResources={true} setExistingGroup={setExistingGroup} />
                                    )
                                })
                                : <></>}
                        </div>
                    </div>}
                <div className='flex justify-end space-x-2'>
                    <button type="submit" className={`${isNewGroupOrselectedGroup() ? 'bg-blue-500 hover:bg-blue-400 cursor-pointer' : 'bg-zinc-500'} rounded p-2 w-fit text-xl focues:outline-none`}>Save</button>
                    {isUpdateGroup ?
                        <div className='flex items-center justify-center cursor-pointer rounded border-2 border-zinc-800 hover:border-2 hover:border-red-400' onClick={() => setModalContent(<DeleteGroupConfirm group={group} />)}>
                            <img src={trashIcon} className='w-8' />
                        </div>
                        : <></>}
                </div>
            </form>
        </div>
    )
}
