import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { SearchContext } from "../../context/SearchContext";
import { ResultsContext } from "../../context/ResultsContext";
import SaveResult from "./SaveResult";
import { isMobile } from "react-device-detect";
const newTab = require("../../assets/icons/open_in_new.png");

export default function MobileResultCard({ data, rowKey, selectResources, displayOnly, index }) {
    const {
        setIsIndex,
        clickHistory
    } = useContext(SearchContext);
    const { setShowResult, setPreview, setResult, resourceSelection, setResourceSelection } = useContext(ResultsContext);

    const [showInfo, setShowInfo] = useState(false);
    const [saved, setSaved] = useState(false);
    const [lastSearchId, setLastSearchId] = useState(0);
    const lastSearch = useSelector((state) => state.search.recentQueries);
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if (lastSearch && Object.values(lastSearch)[0]) {
            setLastSearchId(Object.values(lastSearch)[0].id);
        }
    }, [lastSearch]);

    const docExtensions = ["pdf", "doc", "docx", "ppt", "pptx"];
    const result = data[rowKey];

    const handleClick = () => {
        setIsIndex(false);
        const newResult = { ...data[rowKey] };
        newResult.queryId = lastSearchId;
        setShowInfo(!showInfo);
        setShowResult(true);
        setResult(newResult);
        setPreview(data[rowKey].link);
        clickHistory.setCurrentSelected(result.id);
        clickHistory.setVisitedResults([...clickHistory.visitedResults, result.id]);
        if (result.title.toLowerCase().includes("index of /")) setIsIndex(true);
        return;
    };

    const handleNewTab = () => {
        const link = data[rowKey].link;
        window.open(link, "_blank");
        return;
    };

    const handleresourceSelection = (e) => {
        setIsSelected(e.target.checked)

        if (e.target.checked) {
            setResourceSelection([...resourceSelection, result.id])
        }
        else {
            const newresourceSelection = resourceSelection.filter(id => id !== result.id)
            setResourceSelection([...newresourceSelection])
        }
    }

    return (
        <div
            key={rowKey}
            data-collapse-target="collapse"
            data-collapse="collapse"
            id="result"
            className={`${clickHistory.currentSelected === result.id
                ? "border-2 border-green-400"
                : clickHistory.visitedResults?.includes(result.id) &&
                    clickHistory.currentSelected !== result.id
                    ? "border-2 border-white"
                    : ""
                } ${isMobile ? "text-sm" : ""} w-full cursor-pointer flex flex-row rounded hover:bg-zinc-700 py-3 pr-3 my-2 pl-0 transition-all duration-100 ease-in-out bg-gradient-to-r from-zinc-800 to-zinc-900`}

        >
            {/* First Child */}
            <div className="flex items-start justify-center w-14 h-auto py-2">
                {selectResources ? <input checked={isSelected} onChange={handleresourceSelection} type='checkbox' className="w-6 h-6 cursor-pointer" />
                    : <SaveResult result={result} saved={saved} setSaved={setSaved} displayOnly={displayOnly} className='w-3/4'/>}
                {result.title &&
                    result.title.toLowerCase().includes("index of /") ? (
                    <div className="rounded bg-green-200 w-6 my-1">Idx</div>
                ) : (
                    <></>
                )}
            </div>

            {/* Second Child */}
            <div className="flex flex-col items-start justify-between w-full" onClick={handleClick}>
                {result ? (
                    <div key={result.id} className="flex flex-col text-white w-full space-y-1">
                        <div className="flex flex-row justify-between items-center w-full">
                            <div className="flex flex-row w-fit items-center">
                                <h3
                                    className={`font-bold text-white text-lg underline w-fit poppins-regular`}
                                >
                                    {result.title ? result.title.slice(0, 45) : ""}
                                </h3>

                                {result.link &&
                                    docExtensions.includes(
                                        result.link.split(".").slice(-1)[0]
                                    ) && (
                                        <img
                                            src={require("../../assets/images/document.png")}
                                            className={`${isMobile ? "w-6 h-6" : "w-8 h-8"}`}
                                            alt="document"
                                        />
                                    )}
                            </div>
                            <img
                                src={newTab}
                                className="h-8"
                                onClick={handleNewTab}
                                alt="new tab"
                            />
                        </div>
                        <div>

                            <p className="truncate text-zinc-400 w-3/4">
                                {result.link ? result.link.slice(0, 50) : ""}
                                ...
                            </p>
                        </div>
                        <div className="w-full">
                            <p className={`underline text-zinc-300 text-md`}>{result.snippet}</p>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>

    );
}
