import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { verifyEmailToken } from "../../store/session"
const Loading = () => (
    <div className="flex justify-center items-center w-full h-full">
        <img src={require("../../assets/icons/loading.png")} className="h-26 w-26 rounded-full animate-spin mb-4" alt="loading" />
    </div>
)

export default function VerifyEmail() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const sessionUser = useSelector(state => state.session.user)
    const { token } = useParams()

    useEffect(() => {
        if (token) {
            dispatch(verifyEmailToken(token))
        }
    },[])

    useEffect(() => {
        if (sessionUser?.id) navigate('/search')
    }, [sessionUser])

    if (!token) navigate('/signup')

    return (
        <div className={`h-full w-full flex items-center justify-center bg-zinc-900 text-white`}>
            <div className={`w-full max-w-md p-8 space-y-8 bg-zinc-800 rounded-lg shadow-lg`}>
                <h2 className="text-3xl text-center text-blue-500">Verifying</h2>

                <div className="flex items-center justify-center">
                    <Loading />
                </div>
            </div>
        </div>
    )
}
