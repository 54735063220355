import { useNavigate } from 'react-router-dom'
import { useModal } from '../../context/Modal'
import { useSnackbar } from '../../context/Snackbar'
import { deleteGroup } from '../../store/resourcegroups'

export default function DeleteGroupConfirm({group}) {
    const navigate = useNavigate()
    const {closeModal} = useModal()
    const {openSnackbar} = useSnackbar()

    const deleteResourceGroup = () => {
        deleteGroup(group.id)
            .then(async () => {
                closeModal()
                navigate(`/resourceGroups`)
                openSnackbar('success', 'Resource Group Deleted')
            })
            .catch(() => {
                openSnackbar('error', 'There was an error.')
            })
    }

    return (
        <div className={`w-full p-8 space-y-8 bg-zinc-800 rounded-lg text-white`}>
            <div className='h-1/4 flex items-center justify-center pb-4'>
                <h1 className='text-2xl'>Are you sure?</h1>
            </div>
            <div className='flex justify-center space-x-2'>
                    <button
                        type="submit"
                        className={`bg-red-500 hover:bg-red-400 cursor-pointer rounded p-2 w-fit text-xl focues:outline-none`}
                        onClick={deleteResourceGroup}
                        >Delete</button>
                    <button>Cancel</button>
                </div>
        </div>
    )
}
