import { useContext } from "react";
import { useDispatch } from "react-redux";
import { SearchContext } from "../../../context/SearchContext";
import { bingSettings } from "./BingSettings/bingSettings";
import { googleSettings } from "./GoogleSettings/googleSettings";
import Parameter from "../Parameter.js";
import QueryParam from "../QueryParam.js";
import MobileSearchBarForm from "./MobileSearchBarForm.js";
import * as searchActions from "../../../store/search";

export default function MobileSearchBar({ setStatus, status, selectedOperator, setSelectedOperator }) {
    const {
        showOptions,
        searchState
    } = useContext(SearchContext);
    const dispatch = useDispatch();

    const settings = { Google: googleSettings, Bing: bingSettings };

    const saveQuery = () => {
        const options = {
            q: searchState.query.join(";"),
            hl: searchState.language,
            engine: searchState.engine.toLocaleLowerCase(),
            start: 0,
            string: searchState.string,
        };
        if (searchState.engine === "Bing") {
            options.location = searchState.country;
        } else {
            options.cr = searchState.country;
        }
        dispatch(searchActions.saveQuery(options));
    };

    return (
        <div
            className={`w-full flex flex-col font-bold rounded transition-all duration-300 ease-in-out items-center justify-center px-3`}
            id="search-bar-inner"
            data-collapse="collapse"
        >
            <MobileSearchBarForm setStatus={setStatus} status={status} />
            {searchState.query && searchState.query.length && showOptions ? (
                <div className="flex flex-row align-items-center justify-end w-full">
                    <img
                        className="h-8 cursor-pointer px-1"
                        src={require("../../../assets/icons/save.png")}
                        onClick={() => saveQuery()}
                        alt="save query"
                    />
                    <p
                        className={`px-1 text-white rounded h-8 flex align-items-center hover:text-slate-900 cursor-pointer`}
                        onClick={() => searchState.setQuery([])}
                    >
                        Clear
                    </p>
                </div>
            ) : (
                <></>
            )}

            {showOptions && (
                <div className="flex flex-col w-full">
                    {searchState.query ? (
                        <div className="flex flex-wrap p-1 w-full">
                            {searchState.query.map((param, index) => {
                                if (param.includes(":")) {
                                    return (
                                        <QueryParam
                                            param={param}
                                            index={index}
                                        />
                                    );
                                } else return <></>
                            })}
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className={`flex flex-col bg-zinc-800 rounded w-full my-3`}>
                        <div className={`w-full`}>
                            {Object.keys(settings[searchState.engine].operators).map(
                                (param, index) => (
                                    <Parameter
                                        index={index}
                                        text={param}
                                        param={
                                            settings[searchState.engine].operators[param]
                                        }
                                        selectedOperator={selectedOperator}
                                        setSelectedOperator={setSelectedOperator}
                                    />
                                )
                            )}
                        </div>
                        <div
                            className={`w-full h-full text-zinc-800`}
                        >
                            {(searchState.engine === "Google" || searchState.engine === "Bing") && (
                                <div className="p-2">
                                    <select
                                        // id="normalize"
                                        className="pl-2 cursor-pointer bg-zinc-900 text-white py-1 rounded"
                                        onChange={(e) => {
                                            searchState.setLanguage(
                                                settings[searchState.engine].languages[
                                                e.target.value
                                                ]
                                            )
                                            searchState.updateQuery({ hl: settings[searchState.engine].languages[e.target.value] })
                                        }}
                                    >
                                        <option
                                            selected={searchState.language === ""}
                                            disabled
                                        >
                                            Language
                                        </option>

                                        {Object.keys(
                                            settings[searchState.engine].languages
                                        ).map((name) => (
                                            <option
                                                selected={
                                                    settings[searchState.engine].languages[
                                                    name
                                                    ] === searchState.language
                                                }
                                                value={name}
                                            >
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className="p-2">
                                <select
                                    // id="normalize"
                                    className="pl-2 cursor-pointer text-white bg-zinc-900 py-1 rounded w-full"
                                    onChange={(e) => {
                                        const newCountry = searchState.engine === "Google" ? { cr: settings[searchState.engine].countries[e.target.value] } : { location: settings[searchState.engine].countries[e.target.value] }
                                        searchState.updateQuery(newCountry)
                                        console.log(searchState.currentSearchStatus)
                                        searchState.setCountry(settings[searchState.engine].countries[e.target.value])
                                    }}
                                >
                                    <option
                                        selected={searchState.country === ""}
                                        disabled
                                        className=""
                                    >
                                        Country
                                    </option>

                                    {Object.keys(
                                        settings[searchState.engine].countries
                                    ).map((name) => (
                                        <option
                                            value={name}
                                            selected={
                                                settings[searchState.engine].countries[
                                                name
                                                ] === searchState.country
                                            }
                                        >
                                            {name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
