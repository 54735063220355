import React, { useRef, useState, useEffect, createContext, useContext } from 'react';
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from '@mui/material/SnackbarContent';

// Create a context for the modal
const SnackbarContext = createContext();

// Custom hook to use the modal context
export function useSnackbar() {
  return useContext(SnackbarContext);
}

export default function SnackBarProvider({ children }) {
  const snackbarRef = useRef(document.createElement('div'));
  const [snackbarType, setSnackbarType] = useState("");
  const [message, setMessage] = useState("")
  const [undoAction, setUndoAction] = useState(null)
  const [open, setOpen] = useState(false)

  const openSnackbar = (type, message, actionToUndo) => {
    setSnackbarType(type)
    setMessage(message)
    setOpen(true)
    if (typeof actionToUndo === 'function') {
      setUndoAction(() => actionToUndo)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleUndo = () => {
    if (typeof undoAction === 'function') {
      undoAction()
      setOpen(false)
    }
  }

  const contextValue = {
    openSnackbar,
  };

  const snackbarStyles = {
    success: { bgcolor: 'success.main', color: 'white' },
    error: { bgcolor: 'error.main', color: 'white' },
    warning: { bgcolor: 'warning.main', color: 'black' },
    info: { bgcolor: 'info.main', color: 'white' },
  };

  const snackbarContent = {
    "success" : {
      message: (
        <p className='text-lg'>{message}</p>
      ),
      content: (
        <>
          {undoAction ? <Button color="secondary" size="small" onClick={handleUndo}>
            UNDO
          </Button> : <></>}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      ),
      className: "border-2 border-green-400"
    },
    "error" : {
      message: (
        <p>{message}</p>
      ),
      content: (
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      ),
      className: "border-2 border-red-400"
    }
  }

  useEffect(() => {
    const snackbarRoot = document.getElementById('snackbar-root');
    if (snackbarRoot && snackbarRef.current) {
      snackbarRoot.appendChild(snackbarRef.current);
    }
    return () => {
      if (snackbarRoot && snackbarRef.current) {
        snackbarRoot.removeChild(snackbarRef.current);
      }
    };
  }, []);

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      {open && snackbarType && ReactDOM.createPortal(
        <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
        <SnackbarContent
        message={snackbarContent[snackbarType]?.message}
        action={snackbarContent[snackbarType]?.content}
        // className={snackbarContent[snackbarType]?.className}
        sx={snackbarStyles[snackbarType]}
        />
      </Snackbar>,
        snackbarRef.current
      )}
    </SnackbarContext.Provider>
  );
}
