
export default function NewGroupForm({
        name,
        setName,
        description,
        setDescription,
        isPrivate,
        setIsPrivate
    }) {
    return (
        <div className='h-96 w-full text-xl'>
            <label className='flex flex-col'>
                Group Name:
                <input value={name} onChange={(e) => setName(e.target.value)} className='rounded bg-zinc-700 border-2 border-zinc-500 px-3 py-2 mt-1' placeholder='New group' />
            </label>
            <label className='flex flex-col space-y-2'>
                Description:
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} className='rounded bg-zinc-700 border-2 border-zinc-500 px-3 py-2 mt-1' />
            </label>
            <label className='flex flex-col space-y-2'>
                Visibility:
                <select onChange={(e) => setIsPrivate(e.target.value === "true" ? true : false)} className='rounded bg-zinc-700 w-fit cursor-pointer px-3 py-2 mt-1' value={isPrivate}>
                    <option value={true}>Private</option>
                    <option value={false}>Public</option>
                </select>
            </label>
        </div>
    )
}
