import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import MobileRecentStat from "./MobileRecentStat";

export default function RecentStat({ object, setSearch, setShowMenu }) {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const path = window.location.pathname
    const hasData = () => (hover && object?.options?.length) || (path.includes(object.subPath))

    if (isMobile) {
        return <MobileRecentStat object={object} setSearch={setSearch} setShowMenu={setShowMenu} />
    }
    else return (
        <div>
            <div
                className={`p-2 flex flex-row items-center cursor-pointer justify-content-between rounded text-2xl ${path.includes(object.path) ? 'bg-amber-800' : (object.path ? 'hover:bg-zinc-700' : '')} shadow-2xl`}
                onClick={() => {
                    if (object.path) {

                        navigate(object.path);
                        setSearch(false);
                        if (isMobile) {
                            setShowMenu(false);
                        }
                    }
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <h1
                    className="poppins-regular"
                >
                    {object.stat}
                </h1>
                {object.new ? <p className="px-1 border-2 border-green-300 rounded text-green-300 text-[1rem]">New!</p> : <></>}
            </div>
            {hasData() ?
                <div
                    className="w-full h-fit bg-zinc-800 shadow-inner shadow-2xl p-2"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    {object?.options.map((option, idx )=> {
                        const { name, path: optionPath, iconPath } = option

                        return (
                            <div
                                className={`p-2 px-2 space-x-4 flex flex-row items-center cursor-pointer justify-center ${(path.includes(optionPath) || (optionPath === '/search/all' && path === '/search')) ? 'bg-amber-800' : 'hover:bg-zinc-700'}`}
                                onClick={() => navigate(optionPath)}
                                key={idx}
                            >
                                <img src={iconPath} className="w-10" />
                                <h1 className="w-full text-xl">{name}</h1>

                                {option.new ? <p className="px-1 border-2 border-green-300 rounded text-green-300 text-[1rem]">New!</p> : <></>}
                            </div>
                        )
                    })}
                </div> : <></>}
        </div>
    );
}
