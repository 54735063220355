import RecentStat from "../RecentStat";
import historyIcon from '../../../assets/images/history.svg'
import bookmarkIcon from "../../../assets/icons/bookmark_FILL.png"
import resourceGroupIcon from '../../../assets/images/resource-group.png'
const isProduction = process.env.NODE_ENV === "production";

export default function RecentStats({setSearch, setShowMenu}) {

  const navBarStats = [
    {
      stat: "Searches",
      subPath: "/search",
      options: [
        {
          name: 'Recent',
          path: "/search/all",
          iconPath: historyIcon
        },
        {
          name: 'Saved',
          path: "/search/saved",
          iconPath: bookmarkIcon
        }
      ]
    },
    {
      stat: "Resources",
      subPath: "/results",
      options: [
        {
          name: 'Recent',
          path: "/results/all",
          iconPath: historyIcon
        },
        {
          name: 'Saved',
          path: "/results/saved",
          iconPath: bookmarkIcon
        },
        // {
        //   name: "Groups",
        //   path: "/resourceGroups",
        //   iconPath: resourceGroupIcon,
        //   new: true
        // }
      ],
      // new: true
    },
    // {
    //   stat: "My Groups",
    //   path: "/resourceGroups",
    // },
  ];

  return Object.values(navBarStats).map((object, idx) => {
    return (
      <RecentStat object={object} setSearch={setSearch} setShowMenu={setShowMenu} key={idx}/>
    );
  });
}
