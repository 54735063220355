import { useState, useContext } from "react";
import ResultCard from "./ResultCard";
import { SearchContext } from "../../context/SearchContext";
import { ResultsContext } from "../../context/ResultsContext";
import { isMobile } from "react-device-detect";

const Loading = () => (
    <div className="flex justify-center items-center w-full h-full">
        <img src={require("../../assets/icons/loading.png")} className="h-26 w-26 rounded-full animate-spin mb-4" alt="loading" />
    </div>
)

export default function Results({ data, selectResources }) {
    const { loadingResults } = useContext(SearchContext);
    const { showResult } = useContext(ResultsContext);
    const isSearch = window.location.pathname.includes('results')
    const isViewAll = !window.location.pathname.includes('saved')

    return (
        <div className={`flex flex-col h-full items-center ${isMobile ? "overflow-x-hidden" : "overflow-y-auto"} ${isMobile && !showResult ? "row-span-2" : ""} ${showResult ? "w-full" : "w-100"}`} id="results">
            {loadingResults ? (
                <Loading />
            ) : (
                <div className={`${showResult || isMobile ? "w-full" : "3xl:w-1/2 2xl:w-1/2 xl:w-full lg:w-full md:w-full"} p-2 h-full space-y-3`}>
                    {data && Object.values(data).length ? Object.keys(data)
                        .reverse()
                        .filter((key) => !data[key].currentPage)
                        .map((rowKey, index) => (
                            <ResultCard key={rowKey} rowKey={rowKey} data={data} index={index} selectResources={selectResources} />
                        )) : (isSearch ? (isViewAll ? (<div className="flex justify-center items-center text-white w-full text-xl h-full">
                        Saved and viewed resources will show up here
                    </div>) : <div className="flex justify-center items-center text-white w-full text-xl h-full" >
                        Saved resources will show up here
                    </div>) :
                        (<div className="flex justify-center items-center text-white w-full text-xl h-full">
                            No results
                        </div>))}
                </div>
            )}
        </div>
    )
}
