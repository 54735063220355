import googleLanguages from "./google-languages.json";
import googleCountries from "./google-countries.json";

const googleOperators = {
  "Site:": { "text": "site:", "example": ".edu", "title": "Enter a domain to find results from that site only" },
  "In title:": { "text": "intitle:", "example": "research paper", "title": "Enter keywords to find results with this text in the title" },
  "In url:": { "text": "inurl:", "example": "pdf", "title": "Enter keywords to find results with this text in the URL" },
  "Include text:": { "text": "intext:", "example": "organic chemistry", "title": "Enter keywords to find results with this text in the page content" },
  "Exclude site:": { "text": "-site:", "example": "wikipedia.org", "title": "Enter a domain to exclude results from that site" },
  "Exclude from title:": { "text": "-intitle:", "example": "login", "title": "Enter keywords to exclude results with this text in the title" },
  "Exclude from url:": { "text": "-inurl:", "example": "forum", "title": "Enter keywords to exclude results with this text in the URL" },
  "Exclude from text:": { "text": "-intext:", "example": "advertisement", "title": "Enter keywords to exclude results with this text in the page content" }
};

export const googleSettings = {operators: googleOperators, countries: googleCountries, languages: googleLanguages}
