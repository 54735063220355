export default function CheckEmail() {
    return (
        <div className={`w-full max-w-md p-8 space-y-8 bg-zinc-800 rounded-lg shadow-lg`}>
            <h2 className="text-3xl text-center text-blue-500">Almost there!</h2>

            <div className="flex items-center justify-center">

                <h2 className="text-2xl text-center text-white">Check your email</h2>
            </div>
        </div>
    )
}
