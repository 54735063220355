import { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/session";
import { Button } from "@mui/material";
import { SearchContext } from "../../context/SearchContext";
import RecentStats from "./RecentStats";
import menuIcon from "../../assets/images/menu-icon.png";
import logo from "../../assets/images/searchdeck-favicon.png";
import profileImg from "../../assets/icons/profile.png";
import plusIcon from "../../assets/images/plus.png"

export default function MobileSideBar({ setSearch }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.session.user);
    const path = window.location.pathname
    const { searchState } = useContext(SearchContext);
    const [showMenu, setShowMenu] = useState(false);

    const handleLogOut = (e) => {
        e.preventDefault();
        setShowMenu(false);
        dispatch(logout()).then(async () => {

            navigate("/");
        })
    };

    const handleNavigate = () => {
        if (user && user.id) {
            navigate("/search")
        }
        else { navigate('/') }
        setShowMenu(false)
    }
    const handleNewSearch = () => {
        searchState.setQuery([]);
        searchState.setString("");
        
        navigate("/search");
        setShowMenu(false)
    };

    return (
        <div className="text-white w-screen bg-zinc-900 fixed h-[5%] z-30">
            {user ? (
                <div className="grid grid-cols-3 justify-between items-center h-full px-2 py-1">
                    <img
                        src={menuIcon}
                        onClick={() => setShowMenu(!showMenu)}
                        className="h-12 rounded-full"
                        alt="menu"
                    />
                    <div
                        className="flex flex-row items-center justify-center cursor-pointer justify-self-center"
                        onClick={handleNavigate}
                    >
                        <img src={logo} className="flex h-8" alt="logo" />
                        <h1 className="text-2xl p-2">SearchDeck</h1>
                    </div>
                </div>
            ) : (
                <div className="grid grid-cols-3 h-full items-center justify-between w-full text-white p-2">

                    <div
                        className="flex flex-row col-span-2 items-center justify-start cursor-pointer text-white px-2"
                        onClick={handleNavigate}
                    >
                        <img src={logo} className="flex h-10" alt="logo" />
                        <h1 className="text-xl p-1">SearchDeck</h1>
                    </div>
                    {path !== '/login' ?
                        <div className="flex flex-row items-center justify-end">
                            <Button
                                variant="contained"
                                onClick={() => navigate("/login")}
                                className="focus:outline-none"
                            >
                                Login
                            </Button>
                        </div>
                        :
                        <div className="flex flex-row items-center justify-end">
                            <Button
                                variant="contained"
                                onClick={() => navigate("/signup")}
                                className="focus:outline-none"
                            >
                                Signup
                            </Button>
                        </div>
                    }
                </div>
            )}
            {showMenu ? (
                <div className="fixed flex bg-zinc-900 w-full h-full z-30">
                    {user ? (
                        <div className="flex flex-col w-full divide-y ">
                            <div className="flex flex-row justify-between items-center">
                                <div className="flex flex-row items-center">
                                    <img
                                        src={profileImg}
                                        onClick={() => setShowMenu(!showMenu)}
                                        className="h-14 p-2 rounded-full"
                                        alt="profile"
                                    />
                                    <p className="pl-4">
                                        {user ? user.username : ""}
                                    </p>
                                </div>
                                <img
                                    src={require("../../assets/icons/logout.png")}
                                    onClick={(e) => handleLogOut(e)}
                                    className="h-8 cursor-pointer"
                                    alt="logout"
                                />
                            </div>
                            <div className="w-full flex flex-col items-start h-fit space-y-2 p-2">
                                <div
                                    onClick={handleNewSearch}
                                    className="flex flex-row text-xl items-center cursor-pointer p-2 py-2 rounded bg-zinc-200 hover:bg-white text-black w-full"
                                >
                                    <img
                                        src={plusIcon}
                                        className="h-8"
                                        alt="new search"
                                    />
                                    Search
                                </div>
                                {/* <div
                                    onClick={() => {
                                        setShowMenu(false)
                                        navigate('/results/all/new')
                                    }}
                                    className="flex flex-row text-xl items-center cursor-pointer p-2 py-2 rounded bg-zinc-200 hover:bg-white text-black w-full"
                                >
                                    <img
                                        src={plusIcon}
                                        className="h-8"
                                        alt="new search"
                                    />
                                    <p className="w-full">
                                        Resource Group
                                    </p>
                                    <p className="px-1 border-2 border-green-300 rounded text-green-300 text-[1rem] bg-green-600">New!</p>
                                </div> */}
                            </div>
                            <RecentStats
                                setSearch={setSearch}
                                setShowMenu={setShowMenu}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
