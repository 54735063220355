import OpenModalButton from "../OpenModalButton";
import TermsOfServicePage from "../TermsOfServicePage";
export default function ToS({acceptedTOS, setAcceptedTOS}) {
    return (
        <div className="w-full flex items-center justify-center text-white">
            <div className="flex flex-row items-center justify-center h-8">
                <input type="checkbox" checked={acceptedTOS} onChange={() => setAcceptedTOS(!acceptedTOS)} className="h-6 w-6"/>
                <span className="w-4"/>
                <label className="w-full m-0">I agree to the <OpenModalButton buttonText="Terms of Service" modalComponent={<TermsOfServicePage />} className="underline"/></label>
            </div>
        </div>
    )
}
