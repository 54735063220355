import bingCountries from "./bing-countries.json";
import bingLanguages from './bing-languages.json'

const operators = {
  "Contains:": { "text": "contains:", "example": "pdf", "title": "Enter a file extension to find pages linking to files of that type" },
  "Filetype:": { "text": "filetype:", "example": "pdf", "title": "Enter a file extension to find pages with files of that type" },
  "In anchor:": { "text": "inanchor:", "example": "buy now", "title": "Enter words to find pages with links containing this anchor text" },
  "In body:": { "text": "inbody:", "example": "artificial intelligence", "title": "Enter words to find pages containing this text in the body" },
  "In title:": { "text": "intitle:", "example": "research paper", "title": "Enter words to find pages with this text in the title" },
  "IP address:": { "text": "ip:", "example": "192.168.1.1", "title": "Enter an IP address to find pages hosted on this address" },
  "Prefer:": { "text": "prefer:", "example": "latest results", "title": "Enter a preference to prioritize results (e.g., recent pages)" },
  "Site:": { "text": "site:", "example": ".edu", "title": "Enter a domain to find pages from this site or domain" },
  "URL:": { "text": "url:", "example": "example.com/about", "title": "Enter a URL or part of it to find matching pages" }
}

export const bingSettings = {countries: bingCountries, languages: bingLanguages, operators}
